<template>
  <div>
    <!--    START:Section::First    -->
    <b-row>
      <!--  START:Select Contact and Thread      -->
      <b-col
        cols="12"
        sm="12"
        lg="6"
      >
        <b-form-group>
          <sw-select
            :name="$t('SelectContactAndThread')"
            classes="mb-0 flex-grow-1"
          >
            <v-select
              v-model="form.contactThread"
              :options="threads"
              label="name"
              :placeholder="$t('SelectContactAndThread')"
              @input="$nextTick(() => checkFormValidation())"
              @search="getThreads"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>

                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>

              <template #option="{ name, contact }">
                <div class="d-flex align-items-center">
                  <avatar
                    :user="contact"
                    class="mr-25"
                  />  - {{ name }}
                </div>
              </template>

              <template #selected-option="{ name, contact }">
                <div class="d-flex">
                  <avatar
                    :user="contact"
                    class="mr-25"
                  />  - {{ name }}
                </div>
              </template>
            </v-select>

            <template #append>
              <b-button
                variant="primary"
                @click="showNewContactModal = true"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />

                {{ $t('AddContact') }}
              </b-button>
            </template>
          </sw-select>
        </b-form-group>
      </b-col>
      <!--  END:Select Contact and Thread      -->

      <!--  START:Select Calculation      -->
      <b-col
        cols="12"
        sm="12"
        lg="6"
      >
        <b-form-group>
          <sw-select :name="$t('calculators.SelectCalculation')">
            <v-select
              v-model="form.calculation"
              :options="calculations"
              label="name"
              :placeholder="$t('calculators.SelectCalculation')"
              @input="$nextTick(() => checkFormValidation())"
              @search="getCalculation"
            >
              <template #no-options="{ search }">
                <span v-if="search.length">
                  {{ $t('NoData') }}
                </span>

                <span v-else>
                  {{ $t('TypeToSearch') }}
                </span>
              </template>
            </v-select>
          </sw-select>
        </b-form-group>
      </b-col>
      <!--  END:Select Calculation      -->
    </b-row>
    <!--    END:Section::First    -->

    <div v-if="form.calculation">
      <!--    START:Section::Basic Information About Client    -->
      <b-card style="z-index: 10">
        <template #header>
          <h4 class="mb-0">
            1. {{ $t('BasicInformationAboutClient') }}
          </h4>
        </template>

        <validation-observer ref="formSection1">
          <b-row>
            <!--    START:Client Type    -->
            <b-col cols="12">
              <b-form-group :label="$t('calculators.ClientType')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.ClientType')"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="form.clientType"
                    stacked
                    class="d-flex flex-column"
                    style="row-gap: .34rem"
                  >
                    <b-form-radio
                      v-for="clientType in clientTypes"
                      :key="clientType.value"
                      :value="clientType.value"
                    >
                      {{ $t(`calculators.clientType.${clientType.text}`) }}

                      <feather-icon
                        v-b-tooltip
                        icon="AlertCircleIcon"
                        class="ml-50"
                        :title="$t(`calculators.clientType.tips.${clientType.text}`)"
                      />
                    </b-form-radio>
                  </b-form-radio-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--    END:Client Type    -->

            <!--    START:Annual energy consumption    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('calculators.AnnualEnergyConsumption', { unit: 'kWh' })">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.AnnualEnergyConsumption', { unit: 'kWh' })"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.annualEnergyConsumption"
                      :label="$t('calculators.AnnualEnergyConsumption', { unit: 'kWh' })"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append is-text>
                      kWh
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--    END:Annual energy consumption    -->

            <!--    START:Annual gross cost    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('calculators.AnnualGrossCostOfElectricity')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.AnnualGrossCostOfElectricity')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.annualGrossCostOfElectricity"
                      :label="$t('calculators.AnnualGrossCostOfElectricity')"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append is-text>
                      PLN
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--    END:Annual gross cost    -->

            <!--    START:Electricity suppliers    -->
            <b-col cols="12">
              <b-form-group class="mb-0">
                <sw-select
                  :name="$t('calculators.ElectricitySuppliers')"
                  rules="required"
                >
                  <v-select
                    v-model="form.electricitySupplier"
                    :options="electricitySuppliers"
                    label="name"
                    :placeholder="$t('calculators.AnnualGrossCostOfElectricity')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getElectricitySuppliers"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
          <!--    END:Electricity suppliers    -->
          </b-row>
        </validation-observer>
      </b-card>
      <!--    END:Section::Basic Information About Client    -->

      <!--    START:Section::Construction    -->
      <b-card style="z-index: 9">
        <template #header>
          <h4 class="mb-0">
            2. {{ $t('Construction1') }}
          </h4>
        </template>

        <validation-observer ref="formSection2">
          <b-row>
            <!--    START:Type Of Structure    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group class="mb-0">
                <sw-select
                  :name="$t('calculators.SelectTypeOfStructure')"
                  :tip="$t('calculators.SelectTypeOfStructureTip')"
                  rules="required"
                >
                  <v-select
                    v-model="form.typeOfStructure"
                    :options="typeOfStructures"
                    label="name"
                    :placeholder="$t('calculators.SelectTypeOfStructure')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getTypeOfStructures"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
            <!--    END:Type Of Structure    -->

            <!--    START:Choose Roof Covering    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group class="mb-0">
                <sw-select
                  :name="$t('calculators.ChooseRoofCovering')"
                  :tip="$t('calculators.ChooseRoofCoveringTip')"
                  rules="required"
                >
                  <v-select
                    v-model="form.roofCovering"
                    :options="roofCoverings"
                    label="name"
                    :placeholder="$t('calculators.ChooseRoofCovering')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getRoofCoverings"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
            <!--    END:Choose Roof Covering    -->

            <!--    START:Select Direction Of Installation Slope    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group class="mb-0">
                <sw-select
                  :name="$t('calculators.SelectDirectionOfInstallationSlope')"
                  :tip="$t('calculators.SelectDirectionOfInstallationSlopeTip')"
                  rules="required"
                >
                  <v-select
                    v-model="form.directionOfInstallationSlope"
                    :options="directionOfInstallationSlopes"
                    label="name"
                    :placeholder="$t('calculators.SelectDirectionOfInstallationSlope')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getDirectionOfInstallationSlopes"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
            <!--    END:Select Direction Of Installation Slope    -->

            <!--    START:Select Installation Angle    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('calculators.SelectInstallationAngle')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.SelectInstallationAngle')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.installationAngle"
                      :label="$t('calculators.SelectInstallationAngle')"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append is-text>
                      {{ $t('Deg') }}
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <!--    END:Select Installation Angle    -->
          </b-row>
        </validation-observer>

        <sw-info-overlay
          :name="$t('OverlayDefault')"
          :show="!formSection1Valid && false"
          icon=""
        />
      </b-card>
      <!--    END:Section::Construction    -->

      <!--    START:Section::Installation Components    -->
      <b-card style="z-index: 8">
        <template #header>
          <h4 class="mb-0">
            3. {{ $t('InstallationComponents') }}
          </h4>
        </template>

        <validation-observer ref="formSection3">
          <!--    START:Suggested Installation Power Alert    -->
          <b-alert
            variant="primary"
            :show="true"
          >
            <div
              class="alert-body d-flex align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="mt-25"
                style="min-width: 1.5rem"
              />

              <div
                class="d-flex flex-nowrap align-items-center"
                style="column-gap: .64rem; row-gap: .34rem;"
              >
                <h5
                  class="mb-0 text-primary d-flex flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  {{ $t('calculators.SuggestedInstallationPowerInKW') }}:

                  <b-badge variant="primary">
                    10.00 kW
                  </b-badge>
                </h5>

                <feather-icon
                  v-b-tooltip.v-primary
                  icon="InfoIcon"
                  class="mt-25"
                  style="min-width: 1rem"
                  :title="$t('calculators.SuggestedInstallationPowerInKWTip')"
                />
              </div>
            </div>
          </b-alert>
          <!--    END:Suggested Installation Power Alert    -->

          <!--    START:Select Photovoltaic Module    -->
          <h5
            class="d-flex align-items-center"
            style="column-gap: .34rem"
          >
            {{ $t('calculators.SelectPhotovoltaicModules') }}

            <feather-icon
              v-b-tooltip.v-primary
              icon="InfoIcon"
              style="min-width: 1rem"
              class="text-primary"
              :title="$t('calculators.SelectPhotovoltaicModulesTip')"
            />
          </h5>

          <b-row class="mb-50">
            <!--    START:Choose Producer    -->
            <b-col
              cols="12"
              lg="4"
            >
              <b-form-group class="mb-0">
                <sw-select
                  :name="$t('ChooseProducer')"
                  rules="required"
                >
                  <v-select
                    v-model="form.photovoltaicModulProducer"
                    :options="photovoltaicModulesProducers"
                    label="name"
                    :placeholder="$t('ChooseProducer')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getPhotovoltaicModulesProducers"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>
                </sw-select>
              </b-form-group>
            </b-col>
            <!--    END:Choose Producer    -->

            <!--    START:Choose Power    -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group>
                <sw-select
                  :name="$t('ChoosePower')"
                  classes="mb-0 flex-grow-1"
                  is-text
                  rules="required"
                >
                  <v-select
                    v-model="form.photovoltaicModulPower"
                    :options="photovoltaicModulesPowers"
                    label="name"
                    :placeholder="$t('ChoosePower')"
                    @input="$nextTick(() => checkFormValidation())"
                    @search="getPhotovoltaicModulesPowers"
                  >
                    <template #no-options="{ search }">
                      <span v-if="search.length">
                        {{ $t('NoData') }}
                      </span>

                      <span v-else>
                        {{ $t('TypeToSearch') }}
                      </span>
                    </template>
                  </v-select>

                  <template #append>
                    kW
                  </template>
                </sw-select>
              </b-form-group>
            </b-col>
            <!--    END:Choose Power    -->

            <!--    START:Chose Number Of Panels    -->
            <b-col
              cols="12"
              md="6"
              lg="4"
            >
              <b-form-group :label="$t('ChooseNumberOfPanels')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('ChooseNumberOfPanels')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.photovoltaicModulNumberOfPanels"
                      :label="$t('ChoosePower')"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append is-text>
                      {{ $t('Pro') }}.
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <!--    END:Chose Number Of Panels    -->
          </b-row>
          <!--    END:Select Photovoltaic Module    -->

          <!--    START:The Power of the Created Installation Alert    -->
          <b-alert
            variant="primary"
            :show="true"
          >
            <div
              class="alert-body d-flex align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="mt-25"
                style="min-width: 1.5rem"
              />

              <div
                class="d-flex flex-nowrap align-items-center"
                style="column-gap: .64rem; row-gap: .34rem;"
              >
                <h5
                  class="mb-0 text-primary d-flex flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  {{ $t('calculators.ThePowerOfTheCreatedInstallationInKW') }}:

                  <b-badge variant="primary">
                    10.00 kW
                  </b-badge>
                </h5>

                <feather-icon
                  v-b-tooltip.v-primary
                  icon="InfoIcon"
                  class="mt-25"
                  style="min-width: 1rem"
                  :title="$t('calculators.ThePowerOfTheCreatedInstallationInKWTip')"
                />
              </div>
            </div>
          </b-alert>
          <!--    END:The Power of the Created Installation Alert    -->

          <!--    START:Select Inverter    -->
          <h5
            class="d-flex align-items-center"
            style="column-gap: .34rem"
          >
            {{ $t('calculators.SelectInverter') }}

            <feather-icon
              v-b-tooltip.v-primary
              icon="InfoIcon"
              style="min-width: 1rem"
              class="text-primary"
              :title="$t('calculators.SelectInverterTip')"
            />
          </h5>
          <!--    END:Select Inverter    -->

          <!--    START:Select Inverter Table    -->
          <b-table
            :items="form.inverters"
            :fields="selectInverterFields"
            striped
            responsive
            show-empty
            class="border rounded-lg mb-2"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <!--    START:New Inverter Form    -->
            <template #bottom-row>
              <!--    START:Choose Producer    -->
              <td class="w-100">
                <b-form-group class="mb-0 my-25">
                  <sw-select classes="mb-0">
                    <v-select
                      v-model="selectInverterNewItem.producer"
                      :options="inverterProducers"
                      label="name"
                      :placeholder="$t('ChooseProducer')"
                      @input="$nextTick(() => checkFormValidation())"
                      @search="getInverterProducers"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search.length">
                          {{ $t('NoData') }}
                        </span>

                        <span v-else>
                          {{ $t('TypeToSearch') }}
                        </span>
                      </template>
                    </v-select>
                  </sw-select>
                </b-form-group>
              </td>
              <!--    END:Choose Producer    -->

              <!--    START:Chose Quantity    -->
              <td style="min-width: 10rem">
                <b-form-group class="mb-0 my-25">
                  <b-form-input
                    v-model="selectInverterNewItem.quantity"
                    :label="$t('ChoosePower')"
                    type="number"
                    placeholder="0"
                  />
                </b-form-group>
              </td>
              <!--    END:Chose Quantity    -->

              <!--    START:Add Button    -->
              <td class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  :disabled="!selectInverterNewItem.producer || !selectInverterNewItem.quantity"
                  class="m-25"
                  @click="addNewSelectInverterItem"
                >
                  {{ $t('Add') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>
              </td>
            <!--    END:Add Button    -->
            </template>
            <!--    END:New Inverter Form    -->

            <template #cell(actions)="{ index }">
              <b-button
                variant="flat-danger"
                size="sm"
                @click="form.inverters.splice(index, 1)"
              >
                <feather-icon icon="TrashIcon" />

                {{ $t('Delete') }}
              </b-button>
            </template>

            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--    Empty    -->
            <template #empty>
              <empty-content />
            </template>

            <!--    Table Busy    -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
          <!--    END:Select Inverter Table    -->

          <!--    START:Select Optimizer    -->
          <h5
            class="d-flex align-items-center"
            style="column-gap: .34rem"
          >
            {{ $t('calculators.SelectOptimizers') }}

            <feather-icon
              v-b-tooltip.v-primary
              icon="InfoIcon"
              style="min-width: 1rem"
              class="text-primary"
              :title="$t('calculators.SelectOptimizersTip')"
            />
          </h5>
          <!--    END:Select Optimizer    -->

          <!--    START:Select Optimizer Table    -->
          <b-table
            :items="form.optimizers"
            :fields="selectOptimizerFields"
            striped
            responsive
            show-empty
            class="border rounded-lg mb-1"
          >
            <!--    Head    -->
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>

            <!--    START:New Optimizer Form    -->
            <template #bottom-row>
              <!--    START:Choose Producer    -->
              <td class="w-100">
                <b-form-group class="mb-0 my-25">
                  <sw-select classes="mb-0">
                    <v-select
                      v-model="selectOptimizerNewItem.optimizer"
                      :options="optimizers"
                      label="name"
                      :placeholder="$t('ChooseOptimizer')"
                      @input="$nextTick(() => checkFormValidation())"
                      @search="getOptimizers"
                    >
                      <template #no-options="{ search }">
                        <span v-if="search.length">
                          {{ $t('NoData') }}
                        </span>

                        <span v-else>
                          {{ $t('TypeToSearch') }}
                        </span>
                      </template>
                    </v-select>
                  </sw-select>
                </b-form-group>
              </td>
              <!--    END:Choose Producer    -->

              <!--    START:Chose Quantity    -->
              <td style="min-width: 10rem">
                <b-form-group class="mb-0 my-25">
                  <b-form-input
                    v-model="selectOptimizerNewItem.quantity"
                    :label="$t('ChoosePower')"
                    type="number"
                    placeholder="0"
                  />
                </b-form-group>
              </td>
              <!--    END:Chose Quantity    -->

              <!--    START:Add Button    -->
              <td class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  :disabled="!selectOptimizerNewItem.optimizer || !selectOptimizerNewItem.quantity"
                  class="m-25"
                  @click="addNewSelectOptimizerItem"
                >
                  {{ $t('Add') }}

                  <feather-icon icon="PlusIcon" />
                </b-button>
              </td>
            <!--    END:Add Button    -->
            </template>
            <!--    END:New Optimizer Form    -->

            <template #cell(actions)="{ index }">
              <b-button
                variant="flat-danger"
                size="sm"
                @click="form.optimizers.splice(index, 1)"
              >
                <feather-icon icon="TrashIcon" />

                {{ $t('Delete') }}
              </b-button>
            </template>

            <!--    Other    -->
            <template #cell()="row">
              <table-default-cell
                :field="row.field"
                :value="row.value"
              />
            </template>

            <!--    Empty    -->
            <template #empty>
              <empty-content />
            </template>

            <!--    Table Busy    -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
          <!--    END:Select Optimizer Table    -->

          <b-row>
            <!--    START:Length Of The Trench    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('calculators.LengthOfTheTrenchInMeters')">
                <template #label>
                  {{ $t('calculators.LengthOfTheTrenchInMeters') }}

                  <feather-icon
                    v-b-tooltip.v-primary
                    icon="InfoIcon"
                    class="ml-25 mt-n25 text-primary"
                    style="min-width: 1rem"
                    :title="$t('calculators.LengthOfTheTrenchInMetersTip')"
                  />
                </template>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.LengthOfTheTrenchInMeters')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.lengthOfTheTrench"
                      :label="$t('calculators.LengthOfTheTrenchInMeters')"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append is-text>
                      {{ $t('Meters') }}
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--    END:Length Of The Trench    -->

            <!--    START:Renting An Excavator    -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group :label="$t('calculators.RentingAnExcavator')">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('calculators.RentingAnExcavator')"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="form.rentingAnExcavator"
                      :label="$t('calculators.RentingAnExcavator')"
                      type="number"
                      placeholder="0"
                    />

                    <b-input-group-append>
                      <v-select
                        v-model="form.rentingAnExcavatorUnit"
                        :options="[
                          { name: 'RBG', value: 'RBG' },
                          { name: 'M', value: 'M' },
                          { name: 'M2', value: 'M2' },
                          { name: 'M3', value: 'M3' },
                          { name: `${$t('Pro')}.`, value: 'PIECES' },
                        ]"
                        label="name"
                        :clearable="false"
                        style="min-width: 6rem"
                        @input="$nextTick(() => checkFormValidation())"
                        @search="getOptimizers"
                      >
                        <template #no-options="{ search }">
                          <span v-if="search.length">
                            {{ $t('NoData') }}
                          </span>

                          <span v-else>
                            {{ $t('TypeToSearch') }}
                          </span>
                        </template>
                      </v-select>
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          <!--    END:Renting An Excavator    -->
          </b-row>
        </validation-observer>

        <sw-info-overlay
          :name="$t('OverlayDefault')"
          :show="!formSection2Valid && false"
          icon=""
        />
      </b-card>
      <!--    END:Section::Installation Components    -->

      <!--    START:Section::Margin    -->
      <b-card style="z-index: 7">
        <b-form-group class="mb-0">
          <b-form-radio-group
            v-model="form.margins.active"
            stacked
            class="d-flex flex-column radio-group-cursor-pointer radio-group-full-width radio-group-lg-before-mt-0.64"
            style="row-gap: 1rem"
          >
            <b-form-radio
              v-for="(type, i) in marginTypes"
              :key="type.value"
              :value="type.value"
              :style="`z-index: ${marginTypes.length - i}`"
            >
              <b-row style="row-gap: .5rem">
                <!--   START:Name    -->
                <b-col
                  cols="12"
                  lg="3"
                  class="d-flex align-items-center"
                >
                  <h4 class="mb-0">
                    {{ $t(`calculators.marginType.${type.text}`) }}
                  </h4>
                </b-col>
                <!--   END:Name    -->

                <!--   START:Value    -->
                <b-col
                  cols="12"
                  md="4"
                  lg="3"
                  class="d-flex align-items-center"
                  @click.stop.prevent=""
                >
                  <b-form-group class="mb-0 w-100">
                    <validation-provider
                      :name="$t('calculators.Margin')"
                      rules="required"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="type.margin"
                          :label="$t('calculators.Margin')"
                          type="number"
                          placeholder="0"
                        />

                        <b-input-group-append is-text>
                          {{ i ? 'PLN' : '%' }}
                        </b-input-group-append>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--   END:Value    -->

                <!--   START:Type    -->
                <b-col
                  cols="12"
                  md="5"
                  lg="4"
                  class="d-flex align-items-center"
                  @click.stop.prevent=""
                >
                  <b-form-group class="mb-0 w-100">
                    <sw-select
                      classes="mb-0 w-100"
                      rules="required"
                    >
                      <v-select
                        v-model="type.type"
                        :options="marginCalculationTypes[type.value]"
                        label="name"
                        :get-option-label="({ text }) => $t(`calculators.marginCalculationTypes.${type.value}.${text}`)"
                        :placeholder="$t(`calculators.${ i ? 'UpToTheTotalPrice' : 'NetInstallationPrices' }`)"
                        @input="$nextTick(() => checkFormValidation())"
                      >
                        <template #no-options="{ search }">
                          <span v-if="search.length">
                            {{ $t('NoData') }}
                          </span>

                          <span v-else>
                            {{ $t('TypeToSearch') }}
                          </span>
                        </template>
                      </v-select>
                    </sw-select>
                  </b-form-group>
                </b-col>
                <!--   END:Type    -->

                <!--   START:Result    -->
                <b-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex justify-content-end align-items-center"
                >
                  <b-badge
                    variant="primary"
                    class="px-75 py-50"
                  >
                    <span class="h5 text-white">
                      {{ 100000 | priceFormat }} PLN
                    </span>
                  </b-badge>
                </b-col>
              <!--   END:Result    -->
              </b-row>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <sw-info-overlay
          :name="$t('OverlayDefault')"
          :show="!formSection2Valid && false"
          icon=""
        />
      </b-card>
      <!--    END:Section::Margin    -->

      <!--    START:Section::Discounts And Subsidies    -->
      <b-card style="z-index: 6">
        <template #header>
          <h4 class="mb-0">
            5. {{ $t('DiscountsAndSubsidies') }}
          </h4>
        </template>

        <validation-observer ref="formSection4">
          <!--    START:Select The Amount Of The Thermal Modernization Relief    -->
          <b-form-group class="mb-0">
            <sw-select
              :name="$t('calculators.SelectTheAmountOfTheThermalModernizationRelief')"
              :tip="$t('calculators.SelectTheAmountOfTheThermalModernizationReliefTip')"
              rules="required"
            >
              <v-select
                v-model="form.amountOfTheThermalModernizationRelief"
                :options="amountOfTheThermalModernizationReliefs"
                label="name"
                :placeholder="$t('calculators.SelectTheAmountOfTheThermalModernizationRelief')"
                @input="$nextTick(() => checkFormValidation())"
                @search="getAmountOfTheThermalModernizationReliefs"
              >
                <template #no-options="{ search }">
                  <span v-if="search.length">
                    {{ $t('NoData') }}
                  </span>

                  <span v-else>
                    {{ $t('TypeToSearch') }}
                  </span>
                </template>
              </v-select>
            </sw-select>
          </b-form-group>
          <!--    END:Select The Amount Of The Thermal Modernization Relief    -->

          <!--    START:Estimated Amount Of The Relief Granted    -->
          <b-alert
            variant="primary"
            :show="true"
          >
            <div
              class="alert-body d-flex align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="mt-25"
                style="min-width: 1.5rem"
              />

              <div
                class="d-flex flex-nowrap align-items-center"
                style="column-gap: .64rem; row-gap: .34rem;"
              >
                <h5
                  class="mb-0 text-primary d-flex flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  {{ $t('calculators.EstimatedAmountOfTheReliefGranted') }}:

                  <b-badge variant="primary">
                    {{ 20000 | priceFormat }} PLN
                  </b-badge>
                </h5>

                <feather-icon
                  v-b-tooltip.v-primary
                  icon="InfoIcon"
                  class="mt-25"
                  style="min-width: 1rem"
                  :title="$t('calculators.EstimatedAmountOfTheReliefGrantedTip')"
                />
              </div>
            </div>
          </b-alert>
          <!--    END:Estimated Amount Of The Relief Granted    -->

          <!--    START:My Electricity Subsidy    -->
          <b-form-group class="mb-0">
            <sw-select
              :name="$t('calculators.MyElectricitySubsidy')"
              :tip="$t('calculators.MyElectricitySubsidyTip')"
              rules="required"
            >
              <v-select
                v-model="form.electricitySubsidy"
                :options="electricitySubsidies"
                label="name"
                :placeholder="$t('calculators.MyElectricitySubsidy')"
                @input="$nextTick(() => checkFormValidation())"
                @search="getElectricitySubsidies"
              >
                <template #no-options="{ search }">
                  <span v-if="search.length">
                    {{ $t('NoData') }}
                  </span>

                  <span v-else>
                    {{ $t('TypeToSearch') }}
                  </span>
                </template>
              </v-select>
            </sw-select>
          </b-form-group>
          <!--    END:My Electricity Subsidy    -->

          <!--    START:Estimated Value Of The My Electricity Subsidy Awarded    -->
          <b-alert
            variant="primary"
            :show="true"
            class="mb-0"
          >
            <div
              class="alert-body d-flex align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="mt-25"
                style="min-width: 1.5rem"
              />

              <div
                class="d-flex flex-nowrap align-items-center"
                style="column-gap: .64rem; row-gap: .34rem;"
              >
                <h5
                  class="mb-0 text-primary d-flex flex-wrap align-items-center"
                  style="column-gap: .5rem; row-gap: .34rem;"
                >
                  {{ $t('calculators.EstimatedValueOfTheMyElectricitySubsidyAwarded') }}:

                  <b-badge variant="primary">
                    {{ 5000 | priceFormat }} PLN
                  </b-badge>
                </h5>

                <feather-icon
                  v-b-tooltip.v-primary
                  icon="InfoIcon"
                  class="mt-25"
                  style="min-width: 1rem"
                  :title="$t('calculators.EstimatedValueOfTheMyElectricitySubsidyAwardedTip')"
                />
              </div>
            </div>
          </b-alert>
        <!--    END:Estimated Value Of The My Electricity Subsidy Awarded    -->
        </validation-observer>

        <sw-info-overlay
          :name="$t('OverlayDefault')"
          :show="!formSection3Valid && false"
          icon=""
        />
      </b-card>
      <!--    END:Section::Discounts And Subsidies    -->

      <!--    START:Section::My Margin    -->
      <b-card style="z-index: 5">
        <b-form-group class="mb-0">
          <b-form-radio-group
            v-model="form.myMargins.active"
            stacked
            class="d-flex flex-column radio-group-cursor-pointer radio-group-full-width radio-group-lg-before-mt-0.64"
            style="row-gap: 1rem"
          >
            <b-form-radio
              v-for="(type, i) in myMarginTypes"
              :key="type.value"
              :value="type.value"
              :style="`z-index: ${myMarginTypes.length - i}`"
            >
              <b-row style="row-gap: .5rem">
                <!--   START:Name    -->
                <b-col
                  cols="12"
                  lg="3"
                  class="d-flex align-items-center"
                >
                  <h4 class="mb-0">
                    {{ $t(`calculators.myMarginType.${type.text}`) }}
                  </h4>
                </b-col>
                <!--   END:Name    -->

                <!--   START:Value    -->
                <b-col
                  cols="12"
                  md="9"
                  lg="7"
                  class="d-flex align-items-center"
                  @click.stop.prevent=""
                >
                  <b-form-group class="mb-0 w-100">
                    <validation-provider
                      :name="$t('calculators.Margin')"
                      rules="required"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="type.margin"
                          :label="$t('calculators.Margin')"
                          type="number"
                          placeholder="0"
                        />

                        <b-input-group-append is-text>
                          {{ i ? '%' : 'PLN' }}
                        </b-input-group-append>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!--   END:Value    -->

                <!--   START:Result    -->
                <b-col
                  cols="12"
                  md="3"
                  lg="2"
                  class="d-flex justify-content-end align-items-center"
                >
                  <b-badge
                    variant="primary"
                    class="px-75 py-50"
                  >
                    <span class="h5 text-white">
                      {{ 5000 | priceFormat }} PLN
                    </span>
                  </b-badge>
                </b-col>
              <!--   END:Result    -->
              </b-row>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <sw-info-overlay
          :name="$t('OverlayDefault')"
          :show="!formSection3Valid && false"
          icon=""
        />
      </b-card>
      <!--    END:Section::My Margin    -->

      <!--    START:Section::Summary    -->
      <b-card style="z-index: 4">
        <template #header>
          <h4 class="mb-0">
            {{ $t('SummaryOffer') }}
          </h4>
        </template>

        <ul
          class="p-0 m-0 d-flex flex-column"
          style="row-gap: .8rem"
        >
          <!--    START:Net Calculation Value    -->
          <li
            class="d-flex flex-wrap flex-column align-items-start flex-md-row justify-content-end align-items-md-center"
            style="column-gap: 1rem; row-gap: .5rem;"
          >
            <span class="h5 mb-0 font-weight-normal flex-grow-1">
              {{ $t('NetCalculationValue') }}
            </span>

            <span class="h5 mb-0 font-weight-bolder">
              {{ 15000 | priceFormat }} PLN
            </span>
          </li>
          <!--    END:Net Calculation Value    -->

          <!--    START:VAT    -->
          <li
            class="d-flex flex-wrap flex-column align-items-start flex-md-row justify-content-end align-items-md-center"
            style="column-gap: 1rem; row-gap: .5rem;"
          >
            <span class="h5 mb-0 font-weight-normal flex-grow-1">
              {{ $t('VAT') }}
            </span>

            <b-badge variant="primary">
              23 %
            </b-badge>

            <span class="h5 mb-0 font-weight-bolder">
              {{ 15000 | priceFormat }} PLN
            </span>
          </li>
          <!--    END:VAT    -->

          <!--    START:Total Subsidy    -->
          <li
            class="d-flex flex-wrap flex-column align-items-start flex-md-row justify-content-end align-items-md-center"
            style="column-gap: 1rem; row-gap: .5rem;"
          >
            <span class="h5 mb-0 font-weight-normal flex-grow-1">
              {{ $t('TotalSubsidy') }}
            </span>

            <span class="h5 mb-0 font-weight-bolder text-success">
              {{ 15000 | priceFormat }} PLN
            </span>
          </li>
          <!--    END:Total Subsidy    -->

          <!--    START:Thermal Modernization Relief    -->
          <li
            class="d-flex flex-wrap flex-column align-items-start flex-md-row justify-content-end align-items-md-center"
            style="column-gap: 1rem; row-gap: .5rem;"
          >
            <span class="h5 mb-0 font-weight-normal flex-grow-1">
              {{ $t('ThermalModernizationRelief') }}
            </span>

            <span class="h5 mb-0 font-weight-bolder text-success">
              {{ 15000 | priceFormat }} PLN
            </span>
          </li>
          <!--    END:Thermal Modernization Relief    -->

          <!--    START:Discounts Granted    -->
          <li
            class="d-flex flex-wrap flex-column align-items-start flex-md-row justify-content-end align-items-md-center"
            style="column-gap: 1rem; row-gap: .5rem;"
          >
            <span class="h5 mb-0 font-weight-normal flex-grow-1">
              {{ $t('DiscountsGranted') }}
            </span>

            <span class="h5 mb-0 font-weight-bolder text-success">
              {{ 15000 | priceFormat }} PLN
            </span>
          </li>
        <!--    END:Discounts Granted    -->
        </ul>

        <hr>

        <!--    START:Gross Price Less Discounts And Subsidies    -->
        <b-badge
          variant="success"
          class="d-flex flex-wrap align-items-center justify-content-between px-1 py-75"
          style="column-gap: 1rem; row-gap: .5rem;"
        >
          <span
            class="h4 text-white text-wrap mb-0"
            style="text-align: start"
          >
            {{ $t('GrossPriceLessDiscountsAndSubsidies') }}
          </span>

          <span class="h4 text-white mb-0 font-weight-bolder">
            {{ 15000 | priceFormat }} PLN
          </span>
        </b-badge>
        <!--    END:Gross Price Less Discounts And Subsidies    -->

        <!--    START:Button Show Calculation Calculations    -->
        <div class="d-flex justify-content-end mt-50">
          <b-button
            variant="primary"
            size="sm"
            @click="showCalculationCalculationsModal = true"
          >
            {{ $t('ShowCalculationCalculations') }}
          </b-button>
        </div>
      <!--    END:Button Show Calculation Calculations    -->
      </b-card>
      <!--    END:Section::Summary    -->

      <!--    START:Section::Files    -->
      <b-card style="z-index: 3">
        <template #header>
          <h4 class="mb-0">
            {{ $t('OfferFiles') }}
          </h4>
        </template>

        <drag-drop-upload class="mt-n1" />
      </b-card>
      <!--    END:Section::Files    -->

      <!--    START:Section::Bottom Buttons    -->
      <div
        class="d-flex flex-wrap justify-content-end pb-2"
        style="column-gap: 1rem; row-gap: .34rem; z-index: 2;"
      >
        <b-button
          :disabled="!isAllFormsValid"
          variant="success"
        >
          {{ $t('GenerateOffer') }}
        </b-button>

        <b-button
          variant="secondary"
        >
          {{ $t('Offer.SaveAsSketch') }}
        </b-button>
      </div>
      <!--    END:Section::Bottom Buttons    -->
    </div>

    <div v-else>
      <no-content />
    </div>

    <!--    START:Modal::New Contact    -->
    <b-modal
      :visible="showNewContactModal"
      size="xl"
      hide-footer
      @hidden="showNewContactModal = false"
    >
      <create-contact
        is-modal-view
        @close="(e) => {showNewContactModal = false; setNewContact(e)}"
      />
    </b-modal>
    <!--    END:Modal::New Contact    -->

    <!--    START:Modal::Calculation Calculations    -->
    <b-modal
      :title="$t('Details')"
      :visible="showCalculationCalculationsModal"
      size="md"
      hide-footer
      @hidden="showCalculationCalculationsModal = false"
    >
      <ul
        class="p-0 m-0 d-flex flex-column"
        style="row-gap: .8rem"
      >
        <li
          v-for="(calculation, i) in allCalculations"
          :key="i"
          class="d-flex flex-wrap align-items-center justify-content-end"
          style="column-gap: 1rem; row-gap: .5rem;"
        >
          <span class="h5 mb-0 font-weight-normal flex-grow-1">
            {{ $t(calculation.name) }}
          </span>

          <span class="h5 mb-0 font-weight-bolder">
            {{ calculation.value | priceFormat }} PLN
          </span>
        </li>
      </ul>
    </b-modal>
    <!--    END:Modal::Calculation Calculations    -->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { THREADS_SEARCH } from '@/@constants/fields'
import CreateContact from '@/pages/contact.old/Create.vue'
import {
  BFormRadioGroup, BInputGroupAppend, BTable, VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import NoContent from '../../NoContent.vue'

export default {
  components: {
    DragDropUpload,
    BTable,
    ValidationObserver,
    ValidationProvider,
    CreateContact,
    BInputGroupAppend,
    vSelect,
    BFormRadioGroup,
    NoContent,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    form: {
      rentingAnExcavatorUnit: 'RBG',

      margins: {
        active: '',
        percentage: '',
        value: '',
      },

      myMargins: {
        active: '',
        percentage: '',
        value: '',
      },

      inverters: [],
      optimizers: [],
    },

    clientTypes: [
      { text: 'Company', value: 'company' },
      { text: 'Individual', value: 'individual' },
    ],

    marginTypes: [
      {
        text: 'Percentage', value: 'percentage', margin: '', type: '',
      },
      {
        text: 'Value', value: 'value', margin: '', type: '',
      },
    ],

    myMarginTypes: [
      {
        text: 'Value', value: 'value', margin: '',
      },
    ],

    threads: [],
    calculations: [],
    electricitySuppliers: [],
    typeOfStructures: [],
    roofCoverings: [],
    directionOfInstallationSlopes: [],
    photovoltaicModulesProducers: [],
    photovoltaicModulesPowers: [],
    inverterProducers: [],
    optimizers: [],
    amountOfTheThermalModernizationReliefs: [],
    electricitySubsidies: [],
    allCalculations: [
      { name: 'NetCalculationValue', value: 15000 },
      { name: 'VAT', value: 15000 },
      { name: 'TotalSubsidy', value: 15000 },
      { name: 'ThermalModernizationRelief', value: 15000 },
      { name: 'DiscountsGranted', value: 15000 },
      { name: 'GrossPriceLessDiscountsAndSubsidies', value: 15000 },
    ],

    selectInverterFields: [
      { key: 'producer', label: 'Producer' },
      { key: 'quantity', label: 'Quantity' },
      { key: 'actions', label: 'Actions' },
    ],

    selectInverterNewItem: {
      name: '',
      value: '',
    },

    selectOptimizerFields: [
      { key: 'optimizer', label: 'Optimizer' },
      { key: 'quantity', label: 'Quantity' },
      { key: 'actions', label: 'Actions' },
    ],

    selectOptimizerNewItem: {
      name: '',
      value: '',
    },

    marginCalculationTypes: {
      percentage: [
        { text: 'NetInstallationPrices', value: 'netInstallationPrices' },
      ],
      value: [
        { text: 'ToTheNetInstallationPrice', value: 'toTheNetInstallationPrice' },
        { text: 'NumberOfPanels', value: 'numberOfPanels' },
        { text: 'InstallationPowerInKW', value: 'installationPowerInKW' },
      ],
    },

    formSection1Valid: false,
    formSection2Valid: false,
    formSection3Valid: false,
    formSection4Valid: false,
    formSection5Valid: false,
    isAllFormsValid: false,

    showNewContactModal: false,
    showCalculationCalculationsModal: false,
  }),

  watch: {
    form: {
      deep: true,
      async handler() {
        await this.checkFormValidation()
      },
    },
  },

  methods: {
    async checkFormValidation() {
      this.formSection1Valid = await this.$refs.formSection1.validate({ silent: true }) && this.form.annualGrossCostOfElectricity
      this.formSection2Valid = await this.$refs.formSection2.validate({ silent: true }) && this.formSection1Valid
      this.formSection3Valid = await this.$refs.formSection3.validate({ silent: true }) && this.formSection2Valid
          && this.form.inverters.length && this.form.optimizers.length
      this.formSection4Valid = await this.$refs.formSection4.validate({ silent: true }) && this.formSection3Valid

      this.isAllFormsValid = this.formSection4Valid
    },

    async getThreads(search, loading) {
      this.threads = await this.selectSearch(search, loading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 30)
    },
    // eslint-disable-next-line no-unused-vars
    async getCalculation(search, loading) {
      this.calculations = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getElectricitySuppliers(search, loading) {
      this.electricitySuppliers = [{ id: -1, name: 'Test' }, { id: -2, name: 'Test' }, { id: -3, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getTypeOfStructures(search, loading) {
      this.typeOfStructures = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getRoofCoverings(search, loading) {
      this.roofCoverings = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getDirectionOfInstallationSlopes(search, loading) {
      this.directionOfInstallationSlopes = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getPhotovoltaicModulesProducers(search, loading) {
      this.photovoltaicModulesProducers = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getPhotovoltaicModulesPowers(search, loading) {
      this.photovoltaicModulesPowers = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getInverterProducers(search, loading) {
      this.inverterProducers = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getOptimizers(search, loading) {
      this.optimizers = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getAmountOfTheThermalModernizationReliefs(search, loading) {
      this.amountOfTheThermalModernizationReliefs = [{ id: -1, name: 'Test' }]
    },
    // eslint-disable-next-line no-unused-vars
    async getElectricitySubsidies(search, loading) {
      this.electricitySubsidies = [{ id: -1, name: 'Test' }]
    },

    addNewSelectInverterItem() {
      if (
        !this.selectInverterNewItem.producer
        || !this.selectInverterNewItem.quantity
      ) return

      this.form.inverters.push({ ...this.selectInverterNewItem })
      this.selectInverterNewItem = { name: '', value: '' }
    },

    addNewSelectOptimizerItem() {
      if (
        !this.selectOptimizerNewItem.optimizer
        || !this.selectOptimizerNewItem.quantity
      ) return

      this.form.optimizers.push({ ...this.selectOptimizerNewItem })
      this.selectOptimizerNewItem = { name: '', value: '' }
    },

    setNewContact(contact) {
      if (contact?.contactThread) {
        // eslint-disable-next-line no-underscore-dangle
        const _contact = contact
        const thread = contact.contactThread
        delete _contact.contactThread

        this.changeSettings({ ...thread, contact: _contact }, 'contactThread')
      } else {
        this.showAlert('info', this.$i18n.t('RequiredAdditionalAction'), this.$i18n.t('ContactAddedSuccessfullyHoweverThreadIsRequired'))
          .then(() => {
            this.showNewThreadModal = true
            this.newContact = contact
          })
      }
    },
  },
}
</script>
